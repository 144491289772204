import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Breakpoints, Colors } from "../../../styles/styleConstants";
import { HEADER_LEVEL, MODULE_NAME } from "../../../utils/commonConst";
import { getAppUrlByModule, navigateTo } from "../../../utils/commonUtil";
import CtaButton from "../../common/CtaButton";
import HeaderText from "../../common/HeaderText";
import HelperText from "../../common/HelperText";
import Image from "../../common/Image";
// import { Container, Row, Col } from "react-bootstrap";
// import Image from "../common/Image";
// import styled from "styled-components";
// import { Breakpoints, Colors, Spacing } from "../../styles/styleConstants";
// import { HEADER_LEVEL, MODULE_NAME } from "../../utils/commonConst";
// import HeaderText from "../common/HeaderText";
// import HelperText from "../common/HelperText";
// import CtaButton from "../common/CtaButton";
// import { getAppUrlByModule, navigateTo } from "../../utils/commonUtil";

export default function AllAccessHeroV2(props) {
  const { signupCode } = props ?? {};
  const [paramString, setParamString] = useState("");
  useEffect(() => {
    setParamString(window.location.search?.replaceAll("/", ""));
  }, []);

  return (
    <HeroWrapper>
      <Container>
        <Row className="align-items-center">
          <Col md={6} lg={5}>
            <HeaderText
              level={HEADER_LEVEL.LEVEL_6}
              style={{ color: Colors.blue, marginTop: "16px" }}
            >
              ALL-ACCESS
            </HeaderText>
            <HeaderText level={HEADER_LEVEL.LEVEL_1}>
              All the money hacks plus build your investing strategy with a pro.
            </HeaderText>
            <HeaderText
              level={HEADER_LEVEL.LEVEL_6}
              style={{ marginTop: "24px" }}
            >
              <span style={{ fontWeight: 400 }}>
                Never worry if you’re invested in the right things or if your
                investments are in the right accounts to minimize taxes.
              </span>
            </HeaderText>
            {/* <ValuePropTextWrapper>
              <ValuePropIcon src={SuccessCheck} />
              <ValuePropText>Fully personalized</ValuePropText>
            </ValuePropTextWrapper>
            <ValuePropTextWrapper>
              <ValuePropIcon src={SuccessCheck} />
              <ValuePropText>Reviewed by a (human) expert</ValuePropText>
            </ValuePropTextWrapper>
            <ValuePropTextWrapper>
              <ValuePropIcon src={SuccessCheck} />
              <ValuePropText>100% free</ValuePropText>
            </ValuePropTextWrapper> */}
            <CtaWrapper>
              {/* <WaitlistModalCta signupCode={signupCode} /> */}
              <CtaButton
                onClick={(evt) => {
                  // _onSignup();
                  navigateTo(getAppUrlByModule(MODULE_NAME.ALL_ACCESS));
                  evt.preventDefault();
                  evt.stopPropagation();
                }}
                ctaType="primary"
              >
                Get started
              </CtaButton>
            </CtaWrapper>
            <HelperText>45-day 100% money-back guarantee</HelperText>
          </Col>
          <Col md={6} lg={7}>
            <Image className="HeroImage" src="v2.0/all-access0.png" />
          </Col>
        </Row>
      </Container>
    </HeroWrapper>
  );
}

const HeroWrapper = styled.div`
  margin-top: 24px;
  .HeroImage {
    width: 100%;
  }
  @media (max-width: ${Breakpoints.sm}) {
    margin-top: 40px;
  }
`;

const CtaWrapper = styled.div`
  margin: 40px 0px 16px 0px;
`;
